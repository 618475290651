import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SEO"
import WhoWeAreHero from "../components/WhoWeAreHero"
import TeamMemberFeed from "../components/TeamMemberFeed"
import ContactForm from "../components/ContactForm"

const WhoWeAre = ({ data }) => {
  const { hero } = data.mdx.frontmatter
  return (
    <>
      <SEO title="Who We Are" />
      <WhoWeAreHero hero={hero} />
      <TeamMemberFeed />
      <ContactForm />
    </>
  )
}

export default WhoWeAre

export const pageQuery = graphql`
  query WhoWeAre {
    mdx(fileAbsolutePath: { regex: "/content/who-we-are/" }) {
      frontmatter {
        hero {
          title
          subtitle
          heroBackgroundImage
        }
      }
    }
  }
`
