import React from "react"
import TextBlock from "./TextBlock"
import Img from "gatsby-image"

const TeamMemberCard = ({ data, imageLeft = false }) => {
  const { name, jobTitle, info, image } = data;

  return (
    <article
      className={ `team-member-card${ imageLeft ? "__image-left" : "" }` }
    >
      <TextBlock
        className="team-member-card__text"
        title={ name }
        subtitle={ jobTitle }
      >{ info }</TextBlock>
      <Img
        className="team-member-card__image"
        fluid={ image?.map(image => image.childImageSharp.fluid) }
      />
    </article>
  )
}

export default TeamMemberCard