import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import TeamMemberCard from "./TeamMemberCard"
import Panel from "./core/Panel"

const TeamMemberFeed = () => {
  const teamCardsInfo = useStaticQuery(
    graphql`
      query {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/content/staff/" } }
          sort: { fields: frontmatter___order, order: ASC }
        ) {
          edges {
            node {
              id
              frontmatter {
                name
                jobTitle
                image {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                info
                isActive
              }
            }
          }
        }
      }
    `
  )

  let teamCards = teamCardsInfo.allMdx.edges

  return (
    <Panel className="team-member-feed">
      { teamCards
        .filter(({ node }) => node.frontmatter.isActive)
        .map(({ node }, i) => (
            <TeamMemberCard
            key={ node.id }
            data={ node.frontmatter }
            // even indexed cards have their image on the left
            imageLeft={ i % 2 === 0 }
            useSal={ i > 0 }
            />
        )) 
    }
    </Panel>
  )
}

export default TeamMemberFeed
